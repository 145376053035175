import React, {Component} from "react";
import "./Settings.css";
import Typography from "@material-ui/core/Typography";
import {CustomSlider} from "../CustomSlider/CustomSlider";
import {HuePicker} from 'react-color';
import SliderPointer from "../MountainPanorama/SliderPointer";
import Button from "@material-ui/core/Button";
import RefreshIcon from "@material-ui/icons/Refresh";
import withStyles from "@material-ui/core/styles/withStyles";
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import EdgyMountainsIcon from "../MountainIcons/edgyMountains-01.svg"
import WavyMountainsIcon from "../MountainIcons/wavyMountains-01.svg"



const ColorButton = withStyles((theme) => ({
    root: {
        color: theme.palette.getContrastText("#1cb8af"),
        backgroundColor: "#1cb8af",
        '&:hover': {
            backgroundColor: "#159e97",
        },
    },
}))(Button);

class Settings extends Component{

    constructor(props) {
        super(props);
        this.state = {
            defaultNumMountains: Math.round(window.innerHeight / 2 / 75)
        };
    }

    render() {
        return (
            <div className="Settings">
                <div className="SettingsHeader">
                    <Typography variant="h4">Settings</Typography>
                </div>
                <div className="SettingsRow">
                    <div className="SettingsDescription">
                        <Typography noWrap={true} >Number of Mountain Ranges:</Typography>
                    </div>
                    <div className="SettingsSlider">
                        <CustomSlider
                            defaultValue={this.state.defaultNumMountains}
                            aria-labelledby="discrete-slider"
                            valueLabelDisplay="auto"
                            step={1}
                            min={1}
                            max={30}
                            onChange={this.props.onNumberOfMountainsChange}
                        />
                    </div>
                </div>
                <div className="SettingsRow">
                    <div className="SettingsDescription">
                        <Typography noWrap={true} >Topographic Isolation:</Typography>
                    </div>
                    <div className="SettingsSlider">
                        <CustomSlider
                            defaultValue={200}
                            aria-labelledby="discrete-slider"
                            valueLabelDisplay="auto"
                            step={50}
                            min={50}
                            max={500}
                            onChange={this.props.onTopographicIsolationChange}
                        />
                    </div>
                </div>
                <div className="SettingsRow">
                    <div className="SettingsDescription">
                        <Typography noWrap={true} >Topographic Prominence:</Typography>
                    </div>
                    <div className="SettingsSlider">
                        <CustomSlider
                            defaultValue={150}
                            aria-labelledby="discrete-slider"
                            valueLabelDisplay="auto"
                            step={50}
                            min={50}
                            max={500}
                            onChange={this.props.onTopographicProminenceChange}
                        />
                    </div>
                </div>
                <div className="SettingsRow">
                    <div className="SettingsDescription">
                        <Typography noWrap={true} >Hue Selection:</Typography>
                    </div>
                    <div id="hueSelector" className="SettingsSlider">
                        <HuePicker color={this.props.hueSelection} onChange={this.props.onHueSelectionChange} pointer={SliderPointer} width="auto" height="5px"/>
                    </div>
                </div>

                <div className="SettingsRow">
                    <div className="SettingsDescription">
                        <Typography noWrap={true} >Mountain Range Type:</Typography>
                    </div>
                    <div className="">
                        <ToggleButtonGroup
                            value={this.props.mountainType}
                            onChange={this.props.onMountainTypeChange}
                            exclusive
                            aria-label="mountain type"
                            size="small"
                        >
                            <ToggleButton value="wavy" aria-label="edgy-mountains">
                                <img src={WavyMountainsIcon} height={24} width={24} alt="Wavy Mountains"/>
                            </ToggleButton>
                            <ToggleButton value="edgy" aria-label="edgy-mountains">
                                <img src={EdgyMountainsIcon} height={24} width={24} alt="Edgy Mountains"/>
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </div>
                </div>

                <div className="SettingsRowButton">
                    <div className="SettingsButton">
                        <ColorButton onClick={this.props.onGenerateNewMountainsClick} variant="contained" color="secondary" startIcon={<RefreshIcon />}>Get New Mountains</ColorButton>
                    </div>
                </div>

                <div className="SettingsRow">
                    <div className="SettingsInformation">
                        <Typography variant="caption">
                            Tip: Click outside of the settings box to toggle its visibility on and off
                        </Typography>
                    </div>
                </div>
            </div>
        );
    }
}

export default Settings;